import "./aboutslider.scss"

// import Swiper, { Navigation, Pagination } from 'swiper';
import Swiper from 'swiper';

// document.querySelectorAll('people-slider')

// const peopleSliders = Array.from(document.querySelectorAll('.people-slider>.swiper'))
const peopleSliders = Array.from(document.querySelectorAll('.aboutslider-slider'))

peopleSliders.forEach(slider => {
  const swiperElement = slider.querySelector('.swiper')
  const swiper = new Swiper(swiperElement, {
    // configure Swiper to use modules
    // modules: [Navigation, Pagination],
    loop: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 0
      }
    }
  });

  const prevArrow = slider.querySelector('.aboutslider-slider-prev')
  const nextArrow = slider.querySelector('.aboutslider-slider-next')

  prevArrow.addEventListener('click', event => {
    event.preventDefault()
    event.stopPropagation()
    swiper.slidePrev();
  })

  nextArrow.addEventListener('click', event => {
    event.preventDefault()
    event.stopPropagation()
    swiper.slideNext();
  })
});