import "./header.scss"

const header = document.querySelector('.header')
const burger = document.querySelector('.header-mobile-burger-wrapper')
const menuButton = document.querySelector('.header-mobile-menu-button')

window.addEventListener('resize', event => {
  header.classList.remove('mobile-menu-open')
})

menuButton.addEventListener('click', event => {
  event.preventDefault()
  event.stopPropagation()
  header.classList.toggle('mobile-menu-open')
})